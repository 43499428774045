import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import {store, persistor } from "./utils/redux/store";
import { ThemeProvider } from "styled-components";
import { generic } from "./shared/themes";
import { PersistGate } from "redux-persist/integration/react";
import { Auth0Provider } from "@auth0/auth0-react";
import { CRED, SESSION_STORAGE_CACHE} from "./utils/network/auth0Util";

import { datadogRum } from "@datadog/browser-rum";


// Initialize Datadog RUM
datadogRum.init({
    applicationId: "01ce6db4-7a77-4263-9011-e86dae1fa866",
    clientToken: "pubbadfffffa3da099ced7b8dca7ccb58da",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.eu",
    service: "mtn-play",
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    defaultPrivacyLevel: "mask-user-input",
  });


ReactDOM.render(
	<React.StrictMode>
		<Auth0Provider
			audience={CRED.CLIENT.AUDIENCE}
			domain={CRED.CLIENT.DOMAIN}
			clientId={CRED.CLIENT.ID}
			cache={SESSION_STORAGE_CACHE}
			redirectUri={window.location.origin}>

			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ThemeProvider theme={generic} >
						<App />
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</Auth0Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();